<script setup lang="ts">
const {
  bgImgUrl = '/img/two-woman-talking.png',
  compact = false,
  to = '/plateforme-accompagnement',
} = defineProps<{
  bgImgUrl: string
  bgRoueUrl?: string
  compact?: boolean
  to: string
}>()

const icons = [
  { icon: '/img/icone-exercicesprets.svg', title: 'Des exercices', subtitle: 'prêts à utiliser' },
  { icon: '/img/icone-testslogotes.svg', title: 'Des tests logotés', subtitle: 'pour se faire connaitre' },
  { icon: '/img/icone-formationinitiale.svg', title: 'Une formation', subtitle: 'initiale gratuite' },
  { icon: '/img/icone-uncollectif.svg', title: 'Un collectif', subtitle: 'pour s\'entraider' },
]
</script>

<template>
  <VSectionShipDock
    :icons="icons"
    :bg-img-url="bgImgUrl"
    class="mt-10 lg:-mt-20"
    sub-div-classes="pt-5 lg:pt-40"
    left-bg-pos-class="bg-top-left"
    :compact
  >
    <template #title>
      <span class="text-lavender tracking-wider font-ubuntu uppercase">
        <MDCSlot name="title" unwrap="p" />
      </span><br>
      <span class="text-4xl text-white font-laviossa">
        <MDCSlot name="subtitle" unwrap="p" />
      </span>
    </template>

    <template #paragraph>
      <MDCSlot name="paragraph" />

      <div class="flex flex-row lt-2xl:items-center lt-2xl:justify-center">
        <VBtn class="mt-6" :to>
          <span class="mx-6 my-1 text-4 font-bold tracking-widest font-ubuntu uppercase">
            Découvrez en plus
          </span>
        </VBtn>
      </div>
    </template>
  </VSectionShipDock>
</template>
